import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  useNavigate,
  useParams,
  useLocation,
  Outlet,
  useOutletContext,
  useSearchParams,
  Navigate,
} from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import Credit from '../../assets/images/credit.png';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import NotificationMenu from '../NotificationMenu/NotificationMenu';
import { useAuth } from '../../hooks/useAuth';
import { auth } from '../../utils/firebase';
import ProfileCreatedDialog from '../userProfile/profileCreatedDialog';
import LoginButton from './LoginButton';

const notificationsEnabled =
  process.env.REACT_APP_ENABLE_NOTIFICATIONS === 'true';

const monetizationEnabled =
  process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

type CreditsContext = {
  credits: number,
  setCredits: React.Dispatch<React.SetStateAction<number>>,
  maxCredits: number,
  setMaxCredits: React.Dispatch<React.SetStateAction<number>>,
}

const ChatWidgetNavbar = () => {

    const {
      authUser,
    } = useAuth();
    const [credits, setCredits] = useState<number>(0);
    const [maxCredits, setMaxCredits] = useState<number>(0);
  
    useEffect( () => {
      if (authUser && authUser.credits){
        setCredits(authUser.credits.reduce((acc, curr) => acc + curr["remaining"], 0))
        setMaxCredits(authUser.credits.reduce((acc, curr) => acc + curr["maxAmount"], 0))
      }
    }, [authUser])
  
    return (
      <>
        
        <Outlet context={{credits, setCredits, maxCredits, setMaxCredits}}/>
      </>
    )
  }

export default ChatWidgetNavbar;