import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FirebaseError } from 'firebase/app';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { FormMode, IMMUTABLE_USER_KEYS, UserFormType } from './signup.types';
import { useAuth } from '../../hooks/useAuth';
import { useProfileData } from '../../hooks/useProfileData';
import { COMMON_FIREBASE_ERROR_CODE } from '../../config/errors';
import { USER_TYPE } from '../../config/const';
import { deleteData } from '../../utils/api';
import TopImageSection from '../../component/TopImageSection';
import CreatorOnBoardImage from '../../assets/images/creator-list.svg';
import CreatorOnBoardSecondImage from '../../assets/images/create-mimio-2.svg';
import CreatorOnBoardThirdImage from '../../assets/images/create-mimio-2.svg';
import CreatorOnBoardingStepOne from './creatorOnBoardingStepOne';
import CreatorOnBoardingStepTwo from './creatorOnBoardingStepTwo';
import CreatorOnBoardingStepThree from './creatorOnBoardingStepThree';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const PLACE_REG_EX = /^(?=.*[a-zA-Z])([aA-zZ.\s,])+$/;

interface CreatorOnBoardingContainerPropsType {
  formMode?: FormMode;
}

export default function CreatorOnBoardingContainer(
  props: CreatorOnBoardingContainerPropsType
) {
  const { formMode = 'create' } = props;
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [formStep, setFormStep] = React.useState<number>(0);
  const [deleteProfileLoading, setDeleteProfileLoading] = React.useState(false);
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { userName: userNameParam } = useParams();
  const userName = userNameParam?.toLowerCase();
  const navigate = useNavigate();
  const formRef: any = React.useRef();
  const { authUser,setAuthUser, updateUserProfileData } = useAuth();
  const { updateVerifiedStatus , fetchVerifiedStatus} = useAuth();
  const {
    setUserDataToContext,
    creatorProfileData,
    fetchProfileDataFromUserName,
  } = useProfileData();

  const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
  const enableDocRequired = process.env.REACT_APP_ENABLE_DOC_REQUIRED === 'true';
  const enableIntroMessages = process.env.REACT_APP_USE_INTRO_MESSAGES === 'true';
  const enableVoiceFeature = process.env.REACT_APP_ENABLE_VOICE === 'true';

  let defaultInitialValues = {
    fullName: '',
    userName: '',
    normalizedUserName: '',
    normalizedFullName: '',
    shortBio: '',
    email: state?.email || '',
    password: '',
    age: '',
    occupation: '',
    gender: '',
    birthPlace: '',
    residencePlace: '',
    twitterURL: '',
    linkedInURL: '',
    instagramURL: '',
    youtubeURL: '',
    tiktokURL: '',
    userWebPage: [{ web_link: '', disabled: false }],
    youtube_links: [{ url: ''}],
    anythingElse: '',
    userType: USER_TYPE.CREATOR,
    profilePhoto: null,
    bannerPhoto: null,
    userDocument: [],
    existingUserDocument: [],
    deletedUserDocument: [],
    linkCount: 0,
    voiceSample: null,
  };

  const initialValues: any = React.useRef(defaultInitialValues);
  const eventTracker = useAnalyticsEventTracker();

  React.useEffect(() => {
    if (formMode === 'update') {
      fetchUserProfileData();
    }
  }, [formMode]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (userName && creatorProfileData) {
      setInitialUserData(creatorProfileData);
    }
  }, [userName, creatorProfileData]);

  React.useEffect(() => {
    if (formMode === 'create') {
      if (formStep !== 2) setInitialUserData(authUser);
    }
  }, [formMode, authUser]);

  React.useEffect(() => {
    const queryParam = parseInt(searchParams?.get('step'));
    if (
      formMode === 'create' &&
      queryParam &&
      queryParam !== 0 &&
      queryParam !== 1 &&
      queryParam !== 2
    ) {
      navigate('/creators');
      toast.error('Invalid URL. Please check the URL and try again');
      return;
    } else if (queryParam === 0) {
      setFormStep(0);
      scrollToTopPosition();
    } else if (queryParam === 1) {
      setFormStep(1);
      scrollToTopPosition();
    } else if (queryParam === 2) {
      if (enableIntroMessages){
        setFormStep(2);
        scrollToTopPosition();
      } else {
        navigate('/creators');
        toast.error('Invalid URL. Please check the URL and try again');
        return;
      }
    }
  }, [formMode, searchParams, authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const scrollToTopPosition = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth',
    });
  };

  const fetchUserProfileData = () => {
    setFormSubmitting(true);
    fetchProfileDataFromUserName(userName, true, false, navigate);
    setFormSubmitting(false);
  };

  const handleDeleteProfileClick = async () => {
    try {
      setDeleteProfileLoading(true);
      const response = await deleteData({
        user_id: authUser?.documentId,
        delete_profile: true,
      });
      eventTracker('delete-user');
      console.log(response);
      setDeleteProfileLoading(false);
      //Refreshing window
      window.location.reload();
    } catch (error) {
      console.error('API call error:', error);
      eventTracker('delete-user failed');
      setDeleteProfileLoading(false);
    }
  };

  let formValidationSchema: any = {
    profilePhoto: Yup.mixed().notRequired(),
    bannerPhoto: Yup.mixed().notRequired(),
    fullName: Yup.string()
      .required('Full name is required')
      .trim('Please enter valid full name'),
    userName: Yup.string()
      .required('Username is required')
      .matches(/^(\S+$)/g, 'Please enter valid username'),
    shortBio: Yup.string().max(
      1000,
      'Maximum allowed characters for short bio is 1000'
    ),
    email: Yup.string()
      .required('Email is required')
      .email('Please enter valid email'),
    age: Yup.string(),//.required('Age is required'),
    gender: Yup.string(),//.required('Gender is required'),
    occupation: Yup.string()
      //.required('Occupation is required')
      .trim('Please enter valid occupation'),
    birthPlace: Yup.string()
      //.required('City is required')
      .trim('Please enter valid birth city')
      .matches(PLACE_REG_EX, 'Please enter valid birth place'),
    residencePlace: Yup.string()
      //.required('Country is required')
      .trim('Please enter valid residence country')
      .matches(PLACE_REG_EX, 'Please enter valid residence place'),
  };

  const webPageValidationSchema = {
    userWebPage: Yup.array()
      .of(
        Yup.object().shape({
          web_link: Yup.string().matches(
            /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-z]{2,}([/?].*)?$/,
            'Please enter valid link to content'
          ),
        })
      )
      .nullable(),
  };

  // password field validation only perform on registration
  if (formMode === 'create' && formStep === 1) {
    formValidationSchema = webPageValidationSchema;
  }

  //update form mode have single form
  if (formMode === 'update') {
    formValidationSchema = {
      ...formValidationSchema,
      ...webPageValidationSchema,
    };
  }

  const validationSchema = Yup.object().shape(formValidationSchema);

  const handleSubmit = async (values: UserFormType) => {
    const userFormData = { ...values };
  

    // prevent mutation of specific keys
    for (let key of IMMUTABLE_USER_KEYS) {
      delete userFormData[key]
    }

    const invalidYouTubeLinks = userFormData.youtube_links.some((link) => {
      if (!link.url) {
        return false;
      }
      const validYouTubeUrl = /^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtube\.com\/embed\/)[\w-]{11}/;
      return !validYouTubeUrl.test(link.url);
    });

    if (invalidYouTubeLinks) {
      toast.error(
        'Please fix invalid YouTube links before saving. Links must start with https://youtube.com/watch?v={video_id} or https://youtube.com/embed/{video_id}'
      );
      return;
    }
  
    // Proceed with the rest of the validation and submission
    if (formMode === 'update' || formStep === 2) {
      await validateLinkAndDocument(userFormData).then();
      await validateIntroMessage(userFormData).then();
      await processAndValidateVoiceSample(userFormData).then();
      updateUserData(userFormData);
    } else if (formStep === 1) {
      await validateLinkAndDocument(userFormData).then();
      await processAndValidateVoiceSample(userFormData).then();
      if (enableIntroMessages) {
        updateUserData(userFormData, true);
        navigate('/creator-onboarding?step=2', { replace: true });
        setFormStep(2);
        scrollToTopPosition();
      } else {
        updateUserData(userFormData);
      }
    } else {
      navigate('/creator-onboarding?step=1', { replace: true });
      setFormStep(1);
      scrollToTopPosition();
    }
  };  

  const validateIntroMessage = (userFormData: UserFormType) => {
    // check intro message for at least 100 characters && 3 sentences
    return new Promise<void>((resolve, reject) => {
      if (enableIntroMessages) {
        if (!userFormData?.introMessage || userFormData.introMessage.length < 100) {
          toast.error('Please provide at least 100 characters for your AI Persona Introduction');
          reject();
        }
        if (userFormData.introMessage.match(/\(?[^\.\?\!]+[\.!\?]\)?/g).length < 3){
          toast.error('Please provide at least 3 sentences for your AI Persona Introduction');
          reject();
        }
      }
      resolve();
    });
  };

  const validateLinkAndDocument = (userFormData: UserFormType) => {
    //check web links data remove delete key and remove empty links
    return new Promise<void>((resolve, reject) => {
      if (userFormData?.userWebPage?.length >= 0) {
        userFormData.userWebPage = userFormData?.userWebPage?.filter((link) => {
          delete link?.disabled;
          return link?.web_link?.trim().length > 0;
        });
      }
      if (enableDocRequired) {
        if (
          (!userFormData?.userWebPage ||
            userFormData?.userWebPage?.length === 0 ||
            userFormData?.userWebPage?.[0]?.web_link === '') &&
          userFormData.userDocument.length === 0 &&
          userFormData.existingUserDocument.length === 0
        ) {
          toast.error('Please provide at least one document or content link');
          reject();
        }
      }
      resolve();
    });
  };

  const processAndValidateVoiceSample = (userFormData: UserFormType) => {
    // To-do: add preprocessing here

    return new Promise<void>((resolve, reject) => {
      if (enableVoiceFeature) {
        if (userFormData?.voiceSample) {
          const allowedTypes = [
            'mp3',
            'wav',
            'raw',
            'ogg',
            'flac',
            'm4a',
            'mp4',
            'wma',
            'aac',
            'aiff',
            'alac',
            'pcm'
          ];
          if (userFormData?.voiceSample?.name){
            if (!allowedTypes.includes(userFormData?.voiceSample?.name.split('.').pop())) {
              toast.error('The file type for the voice sample is not supported. Please upload an audio file with a .mp3, .wav, or standard audio format');
              reject();
            }
          }
          else if (!allowedTypes.includes(userFormData?.voiceSample?.split('.').pop())) {
            toast.error('The file type for the voice sample is not supported. Please upload an audio file with a .mp3, .wav, or standard audio format');
            reject();
          }
        }
        // To-do: add limits to how long the file can be
      }
      resolve();
    });
  };

  const updateUserData = async (values: UserFormType, skipRedirect?: boolean) => {
    skipRedirect ||= false;
    setFormSubmitting(true);
    // Include updating the verified status in the backend
  if (authUser?.documentId && localVerified !== authUser.verified) {
    await updateVerifiedStatus(authUser.documentId, localVerified);
  }
    updateUserProfileData(values, formMode)
      .then((updatedUserData: any) => {
        setUserDataToContext({
          ...updatedUserData,
          documentId: values?.documentId,
          userType: values?.userType,
          index: values?.index,
        });
        if (!skipRedirect) navigate(`/${values?.userName}`);
        setFormSubmitting(false);
        if (!skipRedirect) toast.success('User details updated successfully');
      })
      .catch((error) => {
        setFormSubmitting(false);
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'There is some error with the user updating. Please try again'
        );
      });
  };

  const handleFormCancel = () => {
    navigate(`/${userName}`);
  };

  const setInitialUserData = (userData: any) => {
    // add disabled key for existing user web links
    if (userData?.userWebPage?.length >= 1) {
      userData.userWebPage = userData?.userWebPage?.map((item: any) => ({
        ...item,
        disabled: true,
      }));
    }
    let values = {
      documentId: userData?.documentId || '',
      fullName: userData?.fullName || '',
      userName: userData?.userName || '',
      shortBio: userData?.shortBio || '',
      email: userData?.email || '',
      twitterURL: userData?.twitterURL || '',
      linkedInURL: userData?.linkedInURL || '',
      instagramURL: userData?.instagramURL || '',
      youtubeURL: userData?.youtubeURL || '',
      tiktokURL: userData?.tiktokURL || '',
      userWebPage:
        userData?.userWebPage?.length >= 1
          ? userData?.userWebPage
          : [{ web_link: '', disabled: false }],
      youtube_links:
        userData?.youtube_links?.length >= 1
          ? userData?.youtube_links
          : [{ url: '' }],
      anythingElse: userData?.anythingElse || '',
      profilePhoto: userData?.profilePhotoURL || '',
      bannerPhoto: userData?.bannerPhotoUrl || '',
      userType: USER_TYPE.CREATOR,
      index: userData?.index || '',
      age: userData?.age || '',
      occupation: userData?.occupation || '',
      gender: userData?.gender || '',
      birthPlace: userData?.birthPlace || '',
      residencePlace: userData?.residencePlace || '',
      existingProfilePhoto: userData?.profilePhoto || '',
      existingProfilePicUrl: userData?.profilePicUrl || '',
      userDocument: [],
      existingUserDocument: userData?.userDocument || [],
      deletedUserDocument: [],
      // parse the file path in voiceSample to get the file name
      voiceSample:
        typeof userData?.voiceSample === 'string'
          ? userData?.voiceSample.replace(/^.*[\\/]/, '')
          : userData?.voiceSample,
      existingVoiceSample: userData?.voiceSample || '',
      existingBannerPhoto: userData?.bannerPhoto || '',
      existingBannerUrl: userData?.bannerPhotoUrl || '',
    }
    if (userData?.introMessage) {
      values['introMessage'] = userData?.introMessage;
    }
    initialValues.current = values;
    formRef?.current?.setValues(values);
  };

  // update the color of the top steps in the form as per current page
  const getCurrentFormSteps = React.useCallback(() => {
    return (
      <Box display='flex' justifyContent='center' p={2} gap={2}>
        {[1, 2, 3].map((data, index) => (
          <Box
            key={index}
            borderRadius={100}
            alignSelf='center'
            justifySelf='center'
            height={12}
            width={12}
            display='flex'
            justifyContent='center'
            style={{
              backgroundColor: index <= formStep ? '#EB84A5' : '#D6D6D6',
            }}
          ></Box>
        ))}
      </Box>
    );
  }, [formStep]);


// Initialize local `verified` state with the value from the authUser context
const [localVerified, setLocalVerified] = React.useState(authUser?.verified ?? true);

// Handle toggle change
const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newVerifiedStatus = event.target.checked;
  setLocalVerified(newVerifiedStatus); // Update local state
};

React.useEffect(() => {
  const fetchAndSetVerifiedStatus = async () => {
    if (authUser?.documentId) {
      const currentVerifiedStatus = await fetchVerifiedStatus(authUser.documentId);
      setLocalVerified(currentVerifiedStatus);
    }
  };

  fetchAndSetVerifiedStatus();
}, [authUser, fetchVerifiedStatus]);


  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'
      justifyItems='center'
      width='100%'
      sx={{
        px: '2rem',
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        flex={0.5}
        width='100%'
        mb={6}
        sx={{
          '@media (min-width: 768px)': {
            maxWidth: '700px',
          },
          '@media (min-width: 1440px)': {
            px: '5rem',
          },
        }}
      >
        {formMode === 'create' && (
            <>
              {getCurrentFormSteps()}
              <TopImageSection
                image={
                  formStep === 0 ? CreatorOnBoardImage :
                  formStep === 1 ? CreatorOnBoardSecondImage :
                  CreatorOnBoardThirdImage
                }
                title={formStep === 0 && 'Create Your Soopra Profile'}
                description={
                  formStep === 0 &&
                  'Let’s learn about you!'
                }
              />
            </>
          )}

        <Formik
          initialValues={initialValues.current}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setTouched,
            isValid,
            setErrors,
            dirty,
          }) => (
            <Form
              id={'form-creator-onboarding'}
              autoComplete='off'
              noValidate
              style={{
                width: '100%',
              }}
            >
              {formMode === 'create' ? (
                formStep === 2 ? (
                  <CreatorOnBoardingStepThree
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    isValid={isValid}
                    dirty={dirty}
                    formSubmitting={formSubmitting}
                    formMode={formMode}
                    userData={authUser}
                  />
                ) :
                formStep === 1 ? (
                  <CreatorOnBoardingStepTwo
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    isValid={isValid}
                    dirty={dirty}
                    formSubmitting={formSubmitting}
                    formMode={formMode}
                  />
                ) : (
                  <CreatorOnBoardingStepOne
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    isValid={isValid}
                    dirty={dirty}
                    formSubmitting={formSubmitting}
                    formMode={formMode}
                    setErrors={setErrors}
                  />
                )
              ) : ( // update
                <>
                  <Box
                    mb={1}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='100%'
                  >
                    <Typography variant='body1' fontWeight='600' color='#000' fontSize='1.25rem'>
                      Edit Info
                    </Typography>
                      </Box>

                      <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: localVerified ? '1px solid black' : '1px solid grey',
                            borderRadius: 3,
                            p: 1,
                            width: '100%',
                            marginBottom: '15px'
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={localVerified}
                                onChange={handleCheckboxChange}
                                sx={{
                                  color: localVerified ? '#00B1FF' : 'grey',
                                  paddingLeft: '20px',
                                  '&.Mui-checked': {
                                    color: '#00B1FF',
                                  },
                                }}
                              />
                            }
                            label=""
                            sx={{
                              marginRight: 1,
                            }}
                          />
                          <Typography variant="body1" sx={{ color: localVerified ? 'black' : 'grey', }}>
                            <Box component="span" sx={{ fontWeight: 'bold',color: localVerified ? 'black' : 'grey', }}>
                              Publish my AI persona!
                            </Box>
                            <Box component="div">This AI Persona is searchable and visible to all users.</Box>
                          </Typography>
                        </Box>
                      <Box
                        display='flex'
                        justifyContent='left'
                        alignItems='center'
                        width='100%'
                        height='30px'
                        marginBottom='20px'
                        bgcolor='black'
                        color='white'
                        p={2} // Padding inside the box, adjust as needed
                      >
                        <Typography variant='h6'>
                          Profile Info
                        </Typography>
                      </Box>



                      <CreatorOnBoardingStepOne
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setTouched={setTouched}
                        isValid={isValid}
                        dirty={dirty}
                        formSubmitting={formSubmitting}
                        formMode={formMode}
                        setErrors={setErrors}
                      />
                      <Box
                        display='flex'
                        justifyContent='left'
                        alignItems='center'
                        width='100%'
                        height='30px'
                        marginTop='50px'
                        bgcolor='black'
                        color='white'
                        p={2} // Padding inside the box, adjust as needed
                      >
                        <Typography variant='h6'>
                          AI Persona Training Data
                        </Typography>
                      </Box>
                      <CreatorOnBoardingStepTwo
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    isValid={isValid}
                    dirty={dirty}
                    formSubmitting={formSubmitting}
                    formMode={formMode}
                  />
                  <CreatorOnBoardingStepThree
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    isValid={isValid}
                    dirty={dirty}
                    formSubmitting={formSubmitting}
                    formMode={formMode}
                    userData={authUser}
                  />
                </>
              )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center', // Adjust this as needed for alignment
                width: '100%',
                mt: 4,
              }}
            >
              <Button
                id='button-cancel-bottom'
                onClick={handleFormCancel} // Make sure this function exists and navigates to the desired location
                variant='outlined'
                sx={{
                  mr: 1, // Add margin to the right of the cancel button
                  flex: 1, // Make both buttons flex to fill the Box equally
                  color: '#000',
                  borderColor: '#000',
                  '&:hover': {
                    borderColor: '#000',
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                Cancel
              </Button>

              <Button
                id='button-next-onboarding'
                sx={{
                  ml: 1, // Add margin to the left of the save button
                  flex: 1, // Make both buttons flex to fill the Box equally
                }}
                type='submit'
                variant='contained'
                fullWidth
                disabled={!(isValid && dirty) || formSubmitting}
                startIcon={
                  formSubmitting && (
                    <CircularProgress size={16} color='inherit' />
                  )
                }
              >
                {(formMode === 'update') ||
                 (formStep === 2 && enableIntroMessages) ||
                 (formStep === 1 && !enableIntroMessages) ?
                  'Save' : 'NEXT'}
              </Button>
              </Box>
              {formMode === 'update' && enableDevTools && (
                <div
                  style={{
                    border: '2px solid #000',
                    padding: '15px',
                    textAlign: 'center',
                    marginTop: '60px',
                  }}
                >
                  <p style={{ fontSize: '16px' }}>
                    Dev Tools for dev and testing only
                  </p>
                  <Button
                    id='button-dev-delete-profile'
                    onClick={handleDeleteProfileClick}
                    disabled={deleteProfileLoading}
                  >
                    {deleteProfileLoading ? 'Deleting...' : 'Delete Profile'}
                  </Button>

                </div>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
