import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button, Avatar, Grid, Tooltip, CircularProgress } from "@mui/material";
import { fetchCoursesByCreator, deleteCourse, fetchCourseResultsByStudent, getCourseInfo, getSortedCoursesList, fetchCourseProgress, updateCurriculum } from '../../utils/api';
import { COURSE_PRICES, USER_TYPE } from '../../config/const';
import SoopraIcon from '../../assets/images/soopra-icon.png';

interface UserCourseDataProps {
  authUser?;
  profileData?;
}

const showMyCourse = process.env.REACT_APP_SHOW_MY_COURSE === 'true';
const allowSelfPublishedCourses = process.env.REACT_APP_ALLOW_SELF_PUBLISHED_COURSES === 'true';

const UserCourseData: React.FC<UserCourseDataProps> = (props) => {
  const { authUser, profileData } = props;
  const [courses, setCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [filteredEnrolledCourses, setFilteredEnrolledCourses] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingEnrolledCourses, setLoadingEnrolledCourses] = useState(true);

  const {
    documentId = '',
    fullName = '',
    profilePhotoURL = '',
    userName = '',
    index = '',
  } = profileData || {};
  const { documentId: authDocumentId = '' } = authUser || {};

  // profileData includes information about the creator
  // authUser just has basic information

  React.useEffect(() => {
    if (authUser.userType === USER_TYPE.CREATOR && documentId === authDocumentId) {
      fetchCoursesByCreator(documentId)
        .then(data => {
          setCourses(data);
          setLoadingCourses(false);
        })
        .catch(error => {
          console.error('Error fetching courses:', error);
          setLoadingCourses(false);
        });
    }
  }, [documentId]);

  React.useEffect(() => {
    if (authUser && documentId === authDocumentId) {
      fetchCourseResultsByStudent(authUser.uid)
        .then(async (results) => {
          const enrolledCoursesWithDetails = await Promise.all(results.map(async (course) => {
            const courseInfoRes = await getCourseInfo(course.courseId);
            const courseInfo = await courseInfoRes.json();
            const courseProgressRes = await fetchCourseProgress(`course_${course.courseId}.${authUser.documentId}`);
            const courseProgress = courseProgressRes.progress;

            return {
              ...course,
              name: courseInfo.name,
              subject: courseInfo.subject,
              grade: courseInfo.grade,
              creator_name: courseInfo.creator_name,
              creatorAvatar: courseInfo.creator_avatar,
              module_count: courseInfo.module_count,
              course_completed: courseProgress.course_completed,
            };
          }));
          setEnrolledCourses(enrolledCoursesWithDetails);
          setLoadingEnrolledCourses(false);
        })
        .catch(error => {
          console.error('Error fetching enrolled courses:', error);
          setLoadingEnrolledCourses(false);
        });
    }
  }, [authUser]);

  React.useEffect(() => {
    const fetchFilteredEnrolledCourses = async () => {
      if (enrolledCourses.length > 0) {
        const sortedCoursesResponse = await getSortedCoursesList(1, 100);
        const sortedCourses = await sortedCoursesResponse.json();
        const filteredCourses = enrolledCourses.filter(course =>
          sortedCourses.data.some(sortedCourse => sortedCourse.course_id === course.courseId)
        );
        setFilteredEnrolledCourses(filteredCourses);
      }
    };

    fetchFilteredEnrolledCourses();
  }, [enrolledCourses]);

  const handleDeleteCourse = async (courseId) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      try {
        await deleteCourse(courseId);
        setCourses(courses.filter(course => course.course_id !== courseId));
        alert('Course deleted successfully!');
      } catch (error) {
        console.error('Error deleting course:', error);
        alert('Failed to delete course');
      }
    }
  };

  const shouldPublishCourse = async (course: any, shouldPublish: boolean) => {
    if (window.confirm(`Are you sure you want to ${shouldPublish ? "" : "un"}publish your course on ${course?.name}?`)) {
      try {
        // validate course before publishing:
        if (!course?.course_id) {
          throw new Error('Course not found')
        }
        if (shouldPublish) {
          if (!course?.creation_done) {
            throw new Error('Cannot publish incomplete course')
          }
        } else {
          if (course?.student_count) {
            throw new Error('Cannot unpublish course with enrolled students')
          }
        }
        let updatedCourseInfo = {is_published: shouldPublish}
        await updateCurriculum(course.course_id, updatedCourseInfo, true)
        setCourses(courses.map((tmpCourse) => {
          if (tmpCourse.course_id === course.course_id) {
            tmpCourse.is_published = shouldPublish;
            return tmpCourse;
          } else {
            return tmpCourse;
          }
        }))
        alert(`Course ${shouldPublish ? "" : "un"}published successfully!`);
      } catch (error) {
        console.error(`Error ${shouldPublish ? "" : "un"}publishing course: `, error);
        alert(`Failed to ${shouldPublish ? "" : "un"}publish course`);
      }
    }
  }

  const CreatedCourses = () => {
    if (authUser.userType !== USER_TYPE.CREATOR) return <></>;

    return (
      ((showMyCourse || authUser?.beta_tester) && authDocumentId === documentId) ? (
        <Box mt={4} display='flex' flexDirection='column' width='100%' py={3} px={2.5} sx={{ border: '1px solid #D9D9D9' }} gap={1}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography fontWeight='600' color='#000' variant='body1'>
              My Courses
            </Typography>
            <Box display='flex' alignItems='center'>
              <Link id={index ? `href-create-course-${userName}` : ''} to={index ? `/createCourse` : ''} aria-disabled={!index} style={{ cursor: index ? 'pointer' : 'not-allowed', textDecoration: 'none' }}>
                <Button
                  id='button-create-course'
                  size='small'
                  sx={{
                    py: 1,
                    fontWeight: 400,
                    backgroundColor: 'black',
                    color: 'white',
                    width: '200px',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                >
                  + Create New Course
                </Button>
              </Link>
            </Box>
          </Box>

          {loadingCourses ? (
            <Box display='flex' justifyContent='center' mt={2}>
              <Typography height='30px' paddingTop='5px' fontWeight='400' lineHeight='normal' color='#000' variant='body2' sx={{ textAlign: 'center' }}>
                Loading your courses...
              </Typography>
            </Box>
          ) : (
            <>
              {courses.length > 0 ? (
                courses.map(course => (
                  <Grid
                    container
                    key={course.course_id}
                    display='flex'
                    justifyContent={{xs: 'center', md: 'space-between'}}
                    alignItems='center'
                    mt={2}
                    gap={2}
                    sx={{ backgroundColor: '#F6F6F6', padding: 2 }}
                  >
                    <Grid item xs={12} md={5} display={'flex'} alignItems={'center'}>
                      <Avatar src={profilePhotoURL} alt={fullName} sx={{ width: 40, height: 40, mr: 2 }} />
                      <Box>
                        <Typography fontWeight='600' color='#000' variant='body1'>
                          {course.name}
                        </Typography>
                        <Typography variant='body2' color='#000'>
                          {fullName || "Creator Name"}
                        </Typography>
                        <Typography variant='body2' color='#000'>
                          {course.subject}, {course.grade}, {course.module_count.toString() + ' Week Course'}
                        </Typography>
                        <Typography variant='body2' color='#000'>
                          {COURSE_PRICES[course?.is_free_course ? "free_course" : course?.module_count]}
                        </Typography>
                      </Box>
                    </Grid>
                    {(course.is_published) ?
                      <Grid item xs={12} md={6}>
                        <Grid container display={'flex'} justifyContent={'flex-end'} spacing={2} ml={{xs: -1, md: 'auto'}} sx={{width: '100%'}}>
                          <Grid item xs={6} md={4}>
                            <Link
                              to={`/results/${course.course_id}`}
                              style={{}}
                            >
                              <Button variant='outlined' size='small' fullWidth sx={{backgroundColor: 'white'}}>
                                {`Students (${course.student_count || 0})`}
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Link
                              to={`/courses/${course.course_id}`} // Update the route here
                              style={{}}
                              onClick={() => console.log(`Navigating to /courses/${course.course_id}`)}
                            >
                              <Button variant='outlined' size='small' fullWidth sx={{backgroundColor: 'white'}}>
                                View Course
                              </Button>
                            </Link>
                          </Grid>
                          {allowSelfPublishedCourses &&
                            <Grid item xs={12} md={4}>
                              <Tooltip title={(course.student_count) ? "Please contact support@soopra.ai to unpublish courses with enrolled students" : ""} arrow placement="top-end">
                                <span>
                                  <Button
                                    variant='contained'
                                    size='small'
                                    fullWidth
                                    disabled={Boolean(course.student_count)}
                                    onClick={() => shouldPublishCourse(course, false)}
                                    sx={{backgroundColor: course.student_count ?'#E9E9E9 !important' : '#db1d00 !important'}}
                                  >
                                    Unpublish Course
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>
                          }
                        </Grid>
                      </Grid> :
                      <Grid item xs={12} md={6}>
                        <Grid container display={'flex'} justifyContent={'flex-end'} spacing={2} ml={{xs: -1, md: 'auto'}}  sx={{width: '100%'}}>
                          <Grid item xs={6} md={4}>
                            <Link
                              to={`/courses/${course.course_id}`} // Update the route here
                              style={{}}
                              onClick={() => console.log(`Navigating to /courses/${course.course_id}`)}
                            >
                              <Button variant='outlined' size='small' fullWidth sx={{backgroundColor: 'white'}}>
                                Test Course
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Button
                              variant='contained'
                              size='small'
                              fullWidth
                              color='error'
                              onClick={() => handleDeleteCourse(course.course_id)}
                              sx={{backgroundColor: '#db1d00 !important'}}
                            >
                              Delete
                            </Button>
                          </Grid>
                          {allowSelfPublishedCourses &&
                            <Grid item xs={12} md={4}>
                              <Button
                                variant='contained'
                                size='small'
                                fullWidth
                                onClick={() => shouldPublishCourse(course, true)}
                              >
                                Publish Course
                              </Button>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                ))
              ) : (
                <Typography bgcolor='#F6F6F6' height='30px' paddingTop='5px' fontWeight='800' lineHeight='normal' color='#000' variant='body2' sx={{ textAlign: 'center' }}>
                  You have not created any courses.
                </Typography>
              )}
            </>
          )}
        </Box>
      ) : <></>
    )
  };

  const EnrolledCourses = () => {
    return (
      (authDocumentId === documentId) ? (
        <Box mt={4} display='flex' flexDirection='column' width='100%' py={3} px={2.5} sx={{ border: '1px solid #D9D9D9' }} gap={1}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography fontWeight='600' color='#000' variant='body1'>
              Enrolled Courses
            </Typography>
          </Box>

          {loadingEnrolledCourses ? (
            <Box display='flex' justifyContent='center' mt={2}>
              <Typography height='30px' paddingTop='5px' fontWeight='400' lineHeight='normal' color='#000' variant='body2' sx={{ textAlign: 'center' }}>
                Loading enrolled courses...
              </Typography>
            </Box>
          ) : (
            filteredEnrolledCourses.length === 0 ?
            <Box display='flex' justifyContent='center' mt={2}>
              <Typography height='30px' paddingTop='5px' fontWeight='400' lineHeight='normal' color='#000' variant='body2' sx={{ textAlign: 'center' }}>
                No enrolled courses found
              </Typography>
            </Box> :
            <>
              {filteredEnrolledCourses.length > 0 ? (
                filteredEnrolledCourses.map(course => (
                  <Grid
                    container
                    key={course.courseId}
                    display='flex'
                    justifyContent={{xs: 'center', md: 'space-between'}}
                    alignItems='center'
                    mt={2}
                    gap={2}
                    sx={{ backgroundColor: '#F6F6F6', padding: 2 }}
                  >
                    <Grid item xs={12} md={5} display={'flex'}  alignItems={'center'}>
                      <Avatar src={course.creatorAvatar || SoopraIcon} alt={course.name || "Course Name"} sx={{ width: 40, height: 40, mr: 2 }} />
                      <Box>
                        <Typography fontWeight='600' color='#000' variant='body1'>
                          {course.name || "Course Name"}
                        </Typography>
                        <Typography variant='body2' color='#000'>
                          {course.creator_name || "Creator Name"}
                        </Typography>
                        <Typography variant='body2' color='#000'>
                          {course.subject || "Subject"}, {course.grade || "Grade"}, {course?.module_count?.toString() + ' Week Course'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} display='flex' >
                      <Grid container spacing={2} ml={{xs: -1, md: 'auto'}}  sx={{width: '100%'}}>
                        <Grid item xs={6}>
                          <Link to={`/courses/${course.courseId}`}>
                            <Button
                              variant='outlined'
                              size='small'
                              fullWidth
                              sx={{ backgroundColor: 'white' }}
                            >
                              Details
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item xs={6}>
                          <Link to={`/learn/${course.courseId}`}>
                            <Button
                              variant={course.course_completed ? 'outlined' : 'contained'}
                              size='small'
                              fullWidth
                              sx={{ backgroundColor: course.course_completed ? 'white' : 'inherit', }}
                            >
                              {course.course_completed ? 'Review' : 'Take Course'}
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography bgcolor='#F6F6F6' height='30px' paddingTop='5px' fontWeight='800' lineHeight='normal' color='#000' variant='body2' sx={{ textAlign: 'center' }}>
                  You have not enrolled in any courses.
                </Typography>
              )}
            </>
          )}
        </Box>
      ) : <></>
    )
  };

  return (
    <>
      <CreatedCourses />
      <EnrolledCourses />
    </>
  )
}

export default UserCourseData;
