import * as React from 'react';
import {
  FieldArray,
  FormikErrors,
  FormikTouched,
  FormikValues,
  FieldArrayRenderProps,
} from 'formik';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Upload from '@mui/icons-material/Upload';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlined from '@mui/icons-material/AddOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import TwitterIcon from '../../svg/TwitterIcon';
import { getTwitterAuthUrl, handleTwitterCallback, fetchTwitterData} from '../../utils/api';
import { useAuth } from '../../hooks/useAuth';


import { verifyFileSize } from './helper';
import CustomTooltip from '../../component/CustomTooltip';
import { FormMode } from './signup.types';
import { useLocation } from 'react-router-dom';

interface CreatorOnBoardingStepTwoProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  setTouched: any;
  isValid: any;
  dirty: any;
  formSubmitting: boolean;
  formMode: FormMode;
}

export default function CreatorOnBoardingStepTwo(
  props: CreatorOnBoardingStepTwoProps
) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    formMode,
  } = props;
  
  const [twitterUsername, setTwitterUsername] = React.useState<string | null>(null);
  const [isTwitterAuthenticated, setIsTwitterAuthenticated] = React.useState<boolean>(false);
  const { authUser } = useAuth(); 

  const handleTwitterAuth = async () => {
    try {
      const authUrl = await getTwitterAuthUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error during Twitter authentication:', error);
    }
  };

  React.useEffect(() => {
    const handleCallbackMessage = (event) => {
      if (event.data.username) {
        setTwitterUsername(event.data.username);
        setIsTwitterAuthenticated(true);
      }
    };

    window.addEventListener('message', handleCallbackMessage);

    return () => {
      window.removeEventListener('message', handleCallbackMessage);
    };
  }, [setFieldValue]);

  React.useEffect(() => {
    const fetchTwitterInfo = async () => {
      if (!authUser?.documentId) {
        console.error("User ID is undefined. Cannot fetch Twitter data.");
        return;
      }

      try {
        const data = await fetchTwitterData(authUser.documentId);
        
        if (data.is_twitter_authenticated) {
          setTwitterUsername(data.username);
          setIsTwitterAuthenticated(true);
          setFieldValue('twitterURL', `https://twitter.com/${data.username}`);
        }
      } catch (error) {
        console.error('Error fetching Twitter data:', error);
      }
    };

    fetchTwitterInfo();
  }, [authUser?.documentId, setFieldValue]);
  React.useEffect(() => {
    const twitterConnected = localStorage.getItem('twitterConnected');
    if (twitterConnected) {
        toast.success('Twitter connected successfully!');
        localStorage.removeItem('twitterConnected');
    }
}, []);

  const enableVoiceFeature = process.env.REACT_APP_ENABLE_VOICE === 'true';

  const getSocialMediaPrefix = (socialKey) => {
    switch (socialKey) {
      case 'twitter':
        return 'https://twitter.com/';
      default:
        return '';
    }
  };
  const renderFileListItem = React.useCallback(
    (document: any, index: number, onDeleteHandler: () => void) => (
      <Box
        key={`${document.name}-${index}`}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        columnGap='12px'
        mt='8px'
      >
        <Box display='flex' color='grey.800'>
          <InsertDriveFile sx={{ mr: 1, fontSize: '20px' }} />
          <Typography
            align='left'
            variant='subtitle2'
            sx={{
              wordBreak: 'break-word',
              ...(document &&
              typeof document === 'object' &&
              document.hasOwnProperty('is_indexed')
                ? !document.is_indexed
                  ? {
                      '&:hover::before': {
                        content: '"This document is not indexed."',
                        display: 'block',
                        position: 'absolute',
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '4px',
                        borderRadius: '8px',
                        zIndex: 1,
                      },
                    }
                  : {
                      // No hover effect and error message when is_indexed property exists and is true
                    }
                : // If its a new file, it dosent have is_indexed attribute so the hover msg says : Ready for upload
                document.hasOwnProperty('is_indexed')
                ? {}
                : {
                    '&:hover::before': {
                      //content:
                      //  '"This document is ready for upload. Click Save button below"',
                      display: 'block',
                      position: 'absolute',
                      backgroundColor: 'purple',
                      color: 'white',
                      padding: '4px',
                      borderRadius: '4px',
                      zIndex: 1,
                    },
                  }),
              color:
                document &&
                typeof document === 'object' &&
                document.hasOwnProperty('is_indexed') &&
                !document.is_indexed
                  ? 'red' // Set the text color to red if document.is_indexed is False
                  : 'inherit', // Use the default text color otherwise
            }}
          >
            {document && typeof document === 'object' && document?.name
              ? document.name
              : document || ''}
          </Typography>
        </Box>
        <IconButton
          id={`button-delete-${document.name}-${index}`}
          color='error'
          size='small'
          sx={{ width: '30px', height: '30px' }}
          onClick={onDeleteHandler}
        >
          <DeleteIcon
            sx={{
              color: 'error.light',
              fontSize: '18px',
            }}
          />
        </IconButton>
      </Box>
    ),
    []
  );

  //render link to content input with error/info help text
  const renderLinkToContentInput = React.useCallback(
    (
      linkValue: {
        userWebPage: {
          web_link: string;
          disabled: boolean;
          is_indexed?: boolean;
        }[];
      },
      index: number,
      handleChange: (e: React.ChangeEvent<any>) => void,
      handleBlur: (e: React.FocusEvent<any>) => void,
      errors: FormikErrors<FormikValues>,
      touched: FormikTouched<FormikValues>,
      arrayHelpers: FieldArrayRenderProps
    ) => {
      //check link to content is_indexed
      let isError = undefined;
      let message = '';
      if (
        linkValue?.userWebPage?.[index] &&
        linkValue?.userWebPage?.[index]?.web_link &&
        typeof linkValue?.userWebPage === 'object'
      ) {
        if (linkValue?.userWebPage?.[index]?.hasOwnProperty('is_indexed')) {
          if (!(linkValue?.userWebPage as any)?.[index]?.is_indexed) {
            isError = true;
            message = 'This link is not indexed';
          }
        } else {
          isError = false;
          message =
            'This link is ready for upload. Click the save button below.';
        }
      }

      return (
        <TextField
          disabled={linkValue?.userWebPage?.[index]?.disabled || formSubmitting}
          fullWidth
          id={`text-input-userWebPage-${index}-web_link`}
          name={`userWebPage.${index}.web_link`}
          label={`Link to Content ${index >= 1 ? index + 1 : ''}`}
          value={linkValue.userWebPage?.[index]?.web_link}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={(event: any) => {
            if (event?.keyCode === 13) {
              event.preventDefault();
              arrayHelpers.push({ web_link: '', disabled: false });
              setTimeout(() => {
                // set focus on newly created link to content input
                const form = event?.target?.form;
                const index = [...form].indexOf(event.target);
                form[index + 3].focus();
              }, 100);
            }
          }}
          error={
            //@ts-ignore
            touched?.userWebPage?.[index]?.web_link &&
            Boolean(
              (
                errors.userWebPage as FormikErrors<{
                  web_link: string;
                }>
              )?.[index]?.web_link
            )
          }
          helperText={
            //@ts-ignore
            touched?.userWebPage?.[index]?.web_link &&
            (
              errors.userWebPage as FormikErrors<{
                web_link: string;
              }>
            )?.[index]?.web_link
          }
          InputProps={{
            //at the end of input display a info/error with tooltip based on is_indexed value
            endAdornment:
              isError !== undefined &&
              !Boolean(
                (
                  errors.userWebPage as FormikErrors<{
                    web_link: string;
                  }>
                )?.[index]?.web_link
              ) ? (
                <InputAdornment position='end'>
                  <CustomTooltip
                    title={message}
                    arrow
                    type={isError ? 'error' : 'info'}
                  >
                    {isError ? (
                      <ErrorOutlineOutlinedIcon sx={{ fontSize: 18 }} />
                    ) : (
                      <HelpOutlineOutlinedIcon sx={{ fontSize: 18 }} />
                    )}
                  </CustomTooltip>
                </InputAdornment>
              ) : (
                <></>
              ),
          }}
          sx={{
            '& input.Mui-disabled': {
              '-webkit-text-fill-color': isError ? '#ff0000aa' : 'inherit',
            },
          }}
        />
      );
    },
    [formSubmitting]
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const step = searchParams.get('step');

  return (
    <>
    <Box
      display='flex'
      flexDirection='column'
      gap={4}
      width='100%'
      mt={formMode === 'update' ? 4 : 0}
    >
       {step === '1' && (
    <Box display='flex' flexDirection='column' alignItems='center' textAlign='center' mb={4}>
        <Typography variant='h5' fontWeight='bold' gutterBottom>
          Train Your AI Personality
        </Typography>
        <Typography variant='body1'  color='black'>
          This information will be used to train your digital AI Persona
        </Typography>
      </Box>
       )}
      <Box display='flex' flexDirection='column' gap={1} width='100%'>
          <Typography
            variant='subtitle2'
            fontSize='1.1rem'
            color='#000'
            fontWeight={600}
          >
            Connect your X account (Twitter)
          </Typography>
          <Typography variant='subtitle2' fontSize='0.8rem' marginBottom='10px'>
            Your last 100 posts at the time of updating will be used to train your AI personality.
          </Typography>

          {isTwitterAuthenticated ? (
            <Typography variant='body2' color='Green'>
              Connected as: {twitterUsername || 'Authenticated'}
            </Typography>
          ) : (
            <Button
            variant='outlined'
            color='primary'
            onClick={handleTwitterAuth}
            disabled={formSubmitting}
            fullWidth
            startIcon={<TwitterIcon />} 
          >
            Sign in with Twitter
          </Button>
          )}
        </Box>

      <Box display='flex' flexDirection='column' gap={1} width='100%'>
        <Typography
          variant='subtitle2'
          fontSize='1.1rem'
          color='#000'
          fontWeight={600}
        >
          Website, Blog, Etc.
        </Typography>
        <Typography variant='subtitle2' fontSize='0.8rem'>
          Personal website, portfolios, links to your personal identity will
          help train your ai.
        </Typography>
        <Box>
          <FieldArray
            name='userWebPage'
            render={(arrayHelpers) => (
              <>
                {values?.userWebPage?.map(
                  (userWebPageItem: any, index: number) => (
                    <Grid item xs={12} display={'flex'} key={index} mb={2}>
                      {renderLinkToContentInput(
                        values,
                        index,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        arrayHelpers
                      )}
                      <IconButton
                        id={`button-remove-link-${index}`}
                        sx={{
                          backgroundColor: 'error',
                        }}
                        color='error'
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <RemoveCircleIcon
                          sx={{
                            backgroundColor: 'error',
                            height: '24px',
                            width: '24px',
                          }}
                        />
                      </IconButton>
                    </Grid>
                  )
                )}
                <Button
                  id='button-add-link'
                  variant='outlined'
                  fullWidth
                  onClick={() =>
                    arrayHelpers.push({
                      web_link: '',
                      disabled: false,
                    })
                  }
                  startIcon={<AddOutlined />}
                >
                  Add Another Link
                </Button>
              </>
            )}
          />
        </Box>
      </Box>

      <Box display='flex' flexDirection='column' gap={1} width='100%'>
          <Typography
            variant='subtitle2'
            fontSize='1.1rem'
            color='#000'
            fontWeight={600}
          >
            Add YouTube Links
          </Typography>
          <Typography variant='subtitle2' fontSize='0.8rem'>
            Train your AI persona with your YouTube videos. Add as many as you like.
          </Typography>
          <Box>
          <FieldArray
            name='youtube_links'
            render={(arrayHelpers) => (
              <>
                {values?.youtube_links?.map((youtubeLink: any, index: number) => (
                  <Grid item xs={12} display={'flex'} key={index} mb={2}>
                    <TextField
                      fullWidth
                      id={`text-input-youtube_links-${index}-url`}
                      name={`youtube_links.${index}.url`}
                      label={`YouTube Link ${index >= 1 ? index + 1 : ''}`}
                      value={youtubeLink.url}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const validYouTubeUrl = /^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtube\.com\/embed\/)[\w-]{11}/;

                        // Set the field value for the YouTube link
                        handleChange(e);

                        // Check if the URL matches the valid YouTube format
                        if (!validYouTubeUrl.test(inputValue)) {
                          setFieldValue(
                            `youtube_links.${index}.error`,
                            'Invalid YouTube link. Must start with https://youtube.com/watch?v={video_id} or https://youtube.com/embed/{video_id}'
                          );
                        } else {
                          setFieldValue(`youtube_links.${index}.error`, '');
                        }
                      }}
                      onBlur={handleBlur}
                      error={
                        Boolean(touched?.youtube_links?.[index]?.url) &&
                        Boolean(
                          (errors.youtube_links as FormikErrors<{ url: string }>)?.[index]?.url ||
                          values.youtube_links[index]?.error
                        )
                      }
                      helperText={
                        values.youtube_links[index]?.error ||
                        (touched?.youtube_links?.[index]?.url &&
                          (errors.youtube_links as FormikErrors<{ url: string }>)?.[index]?.url)
                      }
                      disabled={youtubeLink.is_indexed || formSubmitting}
                    />
                    <IconButton
                      id={`button-remove-youtube-link-${index}`}
                      sx={{
                        backgroundColor: 'error',
                      }}
                      color='error'
                      onClick={() => arrayHelpers.remove(index)}
                      disabled={formSubmitting}
                    >
                      <RemoveCircleIcon
                        sx={{
                          backgroundColor: 'error',
                          height: '24px',
                          width: '24px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                ))}
                <Button
                  id='button-add-youtube-link'
                  variant='outlined'
                  fullWidth
                  onClick={() =>
                    arrayHelpers.push({
                      url: '',
                    })
                  }
                  startIcon={<AddOutlined />}
                >
                  Add Another YouTube Link
                </Button>
              </>
            )}
          />

          </Box>
        </Box>
      <Box display='flex' flexDirection='column' gap={1} width='100%'>
        <Typography
          variant='subtitle2'
          fontSize='1.1rem'
          color='#000'
          fontWeight={600}
        >
          Upload Documents
        </Typography>
        <Typography variant='subtitle2' fontSize='0.8rem'>
          Upload your documents. File formats supported are txt, docx, pdf and pptx.
        </Typography>
        <Box>
          {formMode === 'update' &&
            values?.existingUserDocument &&
            values.existingUserDocument.length >= 1 &&
            values?.existingUserDocument.map((document: any, index: number) =>
              renderFileListItem(document, index, () => {
                values.existingUserDocument?.splice(index, 1);
                setFieldValue(
                  'existingUserDocument',
                  values.existingUserDocument
                );
                values.deletedUserDocument.push(document);
                setFieldValue(
                  'deletedUserDocument',
                  values.deletedUserDocument
                );
              })
            )}
          {values?.userDocument &&
            values.userDocument.length >= 1 &&
            values?.userDocument.map((document: any, index: number) =>
              renderFileListItem(document, index, () => {
                values.userDocument?.splice(index, 1);
                setFieldValue('userDocument', values.userDocument || null);
              })
            )}
          <Button
            id='button-upload-document'
            disabled={formSubmitting}
            variant='outlined'
            component='label'
            startIcon={<InsertDriveFileOutlinedIcon />}
            sx={{
              px: 4,
              width: '100%',
              mt: 1,
            }}
          >
            Upload a Document
            <input
              type='file'
              hidden
              multiple
              id='file-input-userDocument'
              name='userDocument'
              value={null}
              onClick={(event: any) => {
                // HTML 5 default behavior prevents user to upload the file with same name again but it creates the issue on update page
                //so on click of file input make input value null so it will upload the next file even the file name is same.
                const { target = {} } = event || {};
                target.value = '';
              }}
              onChange={(event) => {
                const files = event?.target?.files || [];
                for (let i = 0; i < files?.length; i++) {
                  const fileSize = Math.round(files[i]?.size / 1024);
                  //Making sure that the document is not empty
                  if (!verifyFileSize(fileSize, 1024000)) {
                    return;
                  }
                  //Making sure file extension is one among 'txt', 'doc', 'docx', 'pdf', and 'pptx'
                  const allowedTypes = [
                    'txt',
                    'docx',
                    'pdf',
                    'pptx',
                  ];
                  if (!allowedTypes.includes(files[i]?.name.split('.').pop())) {
                    toast.error(
                      'The file type selected is not supported. The only supported file types are: txt, docx, pdf, and pptx'
                    );
                    return;
                  }
                  // check the file name exists in already uploaded files
                  const allFiles = values?.userDocument?.concat(
                    values?.existingUserDocument || []
                  );
                  const fileExistsIndex = allFiles?.findIndex(
                    (fileItem: any) =>
                      fileItem?.name.trim() === files[i]?.name?.trim()
                  );
                  // if exists show the file name error
                  if (fileExistsIndex !== -1) {
                    toast.error('The file name already exists');
                    return;
                  }
                  values.userDocument.push(files[i]);
                }
                setFieldValue('userDocument', values?.userDocument);
              }}
            />
          </Button>
          {Boolean(errors?.userDocument) && (
            <Typography
              variant='body1'
              color={'error'}
              fontSize={'0.75rem'}
              mt={1}
            >
              {errors?.userDocument as string}
            </Typography>
          )}
        </Box>
      </Box>

      {/* <Divider
        sx={{
          display: 'flex',
          width: '100%',
        }}
      /> */}

      {/* <Typography
        variant='subtitle2'
        fontSize='0.8rem'
        color='grey.700'
        marginBottom='10px'
      >
        Your privacy is our priority: we securely store and protect your data.
      </Typography>
      <TextField
        disabled={formSubmitting}
        fullWidth
        id='anythingElse'
        label='Anything Else?'
        name='anythingElse'
        value={values.anythingElse}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.anythingElse && Boolean(errors.anythingElse)}
        helperText={touched.anythingElse && errors.anythingElse}
      /> */}

      {enableVoiceFeature && (
        <Box display='flex' flexDirection='column' gap={1} width='100%'>
          <Typography
            variant='subtitle2'
            fontSize='1.1rem'
            color='#000'
            fontWeight={600}
          >
            Upload voice sample mp3 file.
          </Typography>
          <Typography variant='subtitle2' fontSize='0.8rem'>
          Upload a voice sample of 1-2 mins in .mp3, .wav or standard audio format.
        </Typography>

          {values?.voiceSample &&
            renderFileListItem(values?.voiceSample, 1, () => {
              // when delete button is hit, set voiceSample to null
              setFieldValue('voiceSample', null);
            })}

          <Button
            id='button-upload-voice-sample'
            disabled={formSubmitting}
            variant='outlined'
            component='label'
            endIcon={<Upload />}
            sx={{
              px: 4,
              width: '100%',
              mt: 1,
            }}
          >
            Upload Voice Sample
            <input
              type='file'
              accept='audio/*'
              id='file-input-voiceSample'
              name='voiceSample'
              hidden
              onChange={(event) => {
                // max voice sample file size is 50MB
                const fileSize = Math.round(
                  event?.currentTarget?.files?.[0].size / 1024
                );
                if (!verifyFileSize(fileSize, 50000)) {
                  return;
                }

                setFieldValue('voiceSample', event?.currentTarget?.files?.[0]);
              }}
            />
          </Button>
        </Box>
      )}
    </Box>
    </>
  );
}
