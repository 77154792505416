import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import { getCheckoutSessionStatus, getCourseInfo,sendCourseEnrollmentConfirmation } from "../../utils/api";
import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Lottie from "lottie-react";
import ProcessingPayment from '../../assets/animations/ProcessingPayment.json'
import SuccessCoursePurchase from '../../assets/animations/SuccessCoursePurchase.json'
import SuccessSubscriptionPurchase from '../../assets/animations/SuccessSubscriptionPurchase.json'
import SuccessTenCreditsPurchase from '../../assets/animations/SuccessTenCreditsPurchase.json'

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';
const showCourses = process.env.REACT_APP_SHOW_MY_COURSE === "true";

const StripeCompleteCheckout = () => {
  const [status, setStatus] = useState(null);
  const redirectURL = useRef(null);
  const title = useRef<string>("Processing Payment");
  const description = useRef<string>("You will be redirected shortly...");
  const item = useRef<string>(null);
  const [animation, setAnimation] = useState(ProcessingPayment);
  const { authUser, fetchAndSetAuthUserData, token, authLoading } = useAuth();
  const navigate = useNavigate();

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    getCheckoutSessionStatus(authUser?.documentId, sessionId)
      .then((res) => res.json())
      .then(async (data) => {
        if (data.amount_paid === 0){
          await delay(3000)
        }

        const baseURL = window.location.hostname.includes('staging')
            ? 'https://staging.app.soopra.ai'
            : 'https://app.soopra.ai';
        switch (data.item){
          case "4_week_course":
          case "2_week_course":
          case "free_course":
            let courseId = data.item_id;
            getCourseInfo(courseId)
              .then(resp => {
                if (resp.ok) return resp.json();
                else {
                  // To-do: error handling here
                }
              })
              .then(course => {
                title.current = "You're all set!";
                description.current = `Thank you for enrolling in ${course?.creator_name}'s course on ${course?.name}`;
                redirectURL.current = data.redirect_url || '/creators';
                item.current = data.item;
                setAnimation(SuccessCoursePurchase)

                const fullCourseLink = `${baseURL}${data.redirect_url}`;

                sendCourseEnrollmentConfirmation(authUser.email, authUser.fullName, fullCourseLink, course.name)
                      .then(response => {
                        console.log('Course enrollment confirmation email sent successfully:', response);
                      })
                      .catch(error => {
                        console.error('Error sending course enrollment confirmation email:', error);
                      });
              })
            break;
          case "basic_soopra_subscription":
            title.current = "Congratulations!";
            description.current = "You are subscribed to Soopra.";
            redirectURL.current = data.redirect_url || '/creators';
            item.current = data.item;
            setAnimation(SuccessSubscriptionPurchase)
            break;
          case "basic_10_pack":
            title.current = "Congratulations!";
            description.current = "You have purchased a question pack.";
            redirectURL.current = data.redirect_url || '/creators';
            item.current = data.item;
            setAnimation(SuccessTenCreditsPurchase)
            break;
          default:
            // To-do: error handling here
            // console.log(data)
            title.current = `Thank you for your purchase!`;
            description.current = " ";
            redirectURL.current = data.redirect_url || '/creators';
            item.current = data.item;
            setAnimation(SuccessSubscriptionPurchase)
            break;
        }
        setStatus(data.status)
      })
      .catch(err => {
        // To-do: error handling here
      })
  }, [])

  if (!(enableMonetization || showCourses || authUser?.beta_tester || authLoading)){
    return <Navigate to="/creators" />
  }

  if (status === 'open') {
    return (
      <Navigate to={`/${authUser.userName}/purchases`} />
    )
  }

  const handleRedirect = (event: any) => {
    event.preventDefault();
    fetchAndSetAuthUserData(authUser.documentId, token)
    navigate(redirectURL.current || "/creators")
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        mb: '50px'
      }}
    >
      <Lottie
        animationData={animation}
        loop={true}
        style={{
          maxWidth: '500px',
          maxHeight: '400px'
        }}
      />
      <Typography
        fontSize={'1.1rem'}
        fontWeight={700}
        mt={1}
        align='center'
        color={'black'}
      >
        {title.current}
      </Typography>
      <Typography
        fontSize={'1rem'}
        fontWeight={400}
        mt={1}
        align='center'
        color={'black'}
      >
        {description.current}
      </Typography>
      {
        redirectURL.current &&
        <Button
          id={`button-stripe-${item.current}`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            mt: 1,
          }}
          onClick={handleRedirect}
        >
          Continue
        </Button>
      }
    </Box>
  )
};

export default StripeCompleteCheckout;
