import * as React from 'react';
import { SVGProps, memo } from 'react';
const VoiceOffIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.889 9.62492H2C0.89543 9.62492 0 8.72949 0 7.62492V5.62492C0 4.52035 0.895431 3.62492 2 3.62492H3.88877L7.30071 0.287165C7.93377 -0.332141 9 0.116382 9 1.002V12.2476C9 13.1332 7.93377 13.5818 7.30071 12.9625L3.889 9.62492Z" fill="#65635F"/>
    <path d="M11.5 4.625L13.5 6.625M15.5 8.625L13.5 6.625M13.5 6.625L15.5 4.625M13.5 6.625L11.5 8.625" stroke="#65635F" strokeLinecap="round"/>
    </svg>
);

export default memo(VoiceOffIcon);
