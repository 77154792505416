import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { Suspense, useState, useEffect, useRef } from "react";
import { useProfileData } from "../../hooks/useProfileData";
import { useAuth } from "../../hooks/useAuth";
import StripeCheckoutForm from '../StripeCheckoutForm';
import { getPrices } from "../../utils/api";
import VerifiedIcon from '@mui/icons-material/Verified';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

interface PremiumPurchaseButtonProps {
  requiredPremiumKey: string;
  redirectURL?: string;
}

const PremiumPurchaseButton: React.FC<PremiumPurchaseButtonProps> = (props) => {
  const { requiredPremiumKey, redirectURL } = props;
  const { creatorProfileData } = useProfileData();

  const dataPromise = useRef(null);
  const loadedData = useRef(null);
  const isLoading = useRef(false);
  const [ stripeOpen, setStripeOpen ] = useState<boolean>(false);
  const [ stripeItem, setStripeItem ] = useState(null);

  const handlePremiumPurchase = () => {
    setStripeItem(requiredPremiumKey)
    setStripeOpen(true)
  };

  const loadData = () => {
    if (!isLoading.current) {
      isLoading.current = true;
      if (requiredPremiumKey) {
        let promise = getPrices([requiredPremiumKey])
                      .then(res => res.json())
                      .then(data => {
                        if (data) loadedData.current = data[requiredPremiumKey];
                      })
                      .catch(err => {
                        console.log(err)
                        loadedData.current = null;
                      })
                      .finally(() => {
                        isLoading.current = false;
                      })

        return promise;
      } else {
        isLoading.current = false;
        return Promise.resolve();
      }
    }
  }

  function wrapPromise(promise) {
    let status = "pending";
    let result;
    let suspender = promise.then(
      (r) => {
        status = "success";
        result = r;
      },
      (e) => {
        status = "error";
        result = e;
      }
    );
    return {
      read() {
        if (status === "pending") {
          throw suspender;
        } else if (status === "error") {
          throw result;
        } else if (status === "success") {
          return result;
        }
      },
    };
  }

  const Loading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f6f6f6',
          border: '1px solid lightgray',
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem',
            paddingBottom: '0.75rem',
            '& img': {
              marginRight: '0.5rem',
              height: '24px',
            }
          }}
        >
          <Typography
            component='h6'
            variant='h6'
            fontWeight={600}
            sx={{
              fontSize: '16px',
              color: 'black',
              textAlign: 'center',
            }}
          >
            {`Subscribe to ${creatorProfileData.fullName}`}
          </Typography>
        </Box>
        <Box
          sx={{
            pl: '1rem',
            pr: '1rem',
            display: 'flex',
          }}
        >
          <CircularProgress size={30} />
          <Typography
            component='h6'
            variant='h6'
            fontWeight={400}
            sx={{
              fontSize: '14px',
              color: 'black',
              marginBottom: '0.75rem',
              textAlign: 'center',
              ml: 1,
            }}
          >
            Loading...
          </Typography>
        </Box>
        <Button
          id={`button-premium-purchase`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            width: '50%',
            fontSize: '14px',
            marginBottom: '1.5rem'
          }}
          fullWidth
          startIcon={<CircularProgress size={30}/>}
        >
          Loading...
        </Button>
      </Box>
    )
  }

  if (!dataPromise.current && !loadedData.current) {
    dataPromise.current ||= wrapPromise(loadData())
  }

  const RealButton = () => {
    if (!loadedData.current) dataPromise.current.read()

    let priceStr = ""
    if (loadedData.current) {
      let data = loadedData.current;
      if (data.currency === 'usd') priceStr += "$"
      priceStr += data.unit_amount/100
      if (data.type === 'recurring') priceStr += `/${data.recurring.interval}`
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f6f6f6',
          border: '1px solid lightgray',
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '1rem',
          paddingBottom: '0.75rem',
          '& img': {
            marginRight: '0.5rem',
            height: '24px',
          }
        }}>
          <Typography
            component='h6'
            variant='h6'
            fontWeight={600}
            sx={{
              fontSize: '16px',
              color: 'black',
              textAlign: 'center',
            }}
          >
            {`Subscribe to ${creatorProfileData.fullName}`}
          </Typography>
          <VerifiedIcon sx={{ml: 0.5, fill: '#3EACEF'}}/>
        </Box>
        <Box
          sx={{
            pl: '1rem',
            pr: '1rem',
          }}
        >
          <Typography
            component='h6'
            variant='h6'
            fontWeight={400}
            sx={{
              fontSize: '14px',
              color: 'black',
              marginBottom: '0.75rem',
              textAlign: 'center',
            }}
          >
            {loadedData?.current?.description || `Unlock access to ${creatorProfileData.fullName}'s AI persona`}
          </Typography>
        </Box>
        <Button
          id={`button-premium-purchase`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            fontSize: '14px',
            marginBottom: '1.5rem'
          }}
          onClick={handlePremiumPurchase}
        >
          {/* Subscribe */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ml: 2,
              mr: 2
            }}
          >
            <Typography color='white' fontWeight={700} mr={8}>Subscribe</Typography>
            <Typography color='white' fontWeight={700}>{priceStr}</Typography>
          </Box>
        </Button>
        <StripeCheckoutForm item={stripeItem} isOpen={stripeOpen} setIsOpen={setStripeOpen} redirectURL={redirectURL || ""} isPremium={true}/>
      </Box>
    )
  }

  if (!enableMonetization) return <></>

  return (
    <Suspense fallback={<Loading />}>
      <RealButton />
    </Suspense>
  )
}

export default PremiumPurchaseButton
